import PlayerControls from "./controls/player-controls";
import PlayerProgressIndicator from "./player-progress-indicator";
import { useModel } from "../context";
import JawsAligner from "./jaws-aligner";

const Footer = () => {
  const { model } = useModel();

  return (
    <>
      {model.isLoading && (<div className="bg-white/10 backdrop-blur-sm h-full absolute bottom-0 left-0 right-0 z-20" />)}
      <div className="absolute select-none right-5 bottom-5 flex flex-col">
        <PlayerProgressIndicator />
        <JawsAligner />
        <PlayerControls />
      </div>
    </>
  );
};

export default Footer;
