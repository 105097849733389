import { Line, Plane, Text } from "@react-three/drei";
import React from "react";
import Billboard from "./bill-board";
import * as THREE from "three";

const IprPositions = ({ iprPositions }) => {
  return (
    <>
      {iprPositions.map((tooth, index) => {
        const distance = new THREE.Vector3().subVectors(tooth.position, tooth.toothCenter).length();

        return (
          <group key={index}>
            {tooth.position && (
              <>
                <Line
                  points={[tooth.toothCenter.toArray(), tooth.position.toArray()]}
                  color="whitesmoke"
                  lineWidth={2}
                />
                {distance > 1 && (
                  <Billboard position={tooth.position.toArray()}>
                    <Plane args={[15, 5.4]} position={[0, 0, -0.13]}>
                      <meshBasicMaterial color="grey" />
                    </Plane>
                    <Plane args={[14.6, 5]} position={[0, 0, -0.12]}>
                      <meshBasicMaterial color="white" />
                    </Plane>
                    <Plane args={[6, 4]} position={[3.8, 0, -0.11]}>
                      <meshBasicMaterial color="black" />
                    </Plane>
                    <Text
                      position={[-3.2, 0, 0]}
                      color="black"
                      fontSize={2}
                      anchorX="center"
                      anchorY="middle"
                    >
                      {tooth.stripping}
                    </Text>
                    <Text
                      position={[3.8, 0, 0]}
                      color="white"
                      fontSize={2}
                      anchorX="center"
                      anchorY="middle"
                    >
                      {tooth.aligner}
                    </Text>
                  </Billboard>
                )}
              </>
            )}
          </group>
        );
      })}
    </>
  );
};

export default IprPositions;
