import AttachmentSvg from "../assets/svgs/attachment.svg";
import IprSvg from "../assets/svgs/ipr.svg";
import ElasticsSvg from "../assets/svgs/elastics.svg";
import SplitSvg from "../assets/svgs/split.svg";

const FUNCTIONALITIES = [
  { label: "Attach", value: "isAttachment", icon: AttachmentSvg, isMobile: true },
  { label: "IPR", value: "isIpr", icon: IprSvg, isMobile: true },
  { label: "Elastics", value: "isElastic", icon: ElasticsSvg, isMobile: true },
  { label: "Split", value: "isSplit", icon: SplitSvg, isMobile: false },
];

export default FUNCTIONALITIES;
