import axios from 'axios';

const getModelsFromAPI = async (caseId) => {
  try {
    const response = await axios.get(`https://dubaismiledl.com/v2api/api/files/sorted-models/${caseId}`);
    if (response.status !== 200) {
      console.error('Unexpected response status:', response.status);
      return [];
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching models:', error);
    return [];
  }
};

const formatModels = (modelsData) => {
  if (!Array.isArray(modelsData)) {
    console.warn('Invalid models data:', modelsData);
    return [];
  }
  return modelsData.map((model) => {
    if (!model.upperJaw || !model.lowerJaw) {
      console.warn('Missing model data:', model);
      return null;
    }
    return {
      upperJaw: { obj: model.upperJaw?.obj ?? null, mtl: model.upperJaw?.mtl ?? null },
      lowerJaw: { obj: model.lowerJaw?.obj ?? null, mtl: model.lowerJaw?.mtl ?? null },
    };
  }).filter(Boolean);
};

const loadModels = async (caseId) => {
  try {
    const modelsData = await getModelsFromAPI(caseId);
    return formatModels(modelsData);
  } catch (error) {
    console.error('Error loading models:', error);
    return [];
  }
};

let MODELS = [];

const initializeModels = async (caseId) => {
  try {
    const modelsData = await getModelsFromAPI(caseId);
    MODELS.length = 0; // Clear the array to maintain reference integrity
    MODELS.push(...formatModels(modelsData)); // Populate the array with new data
  } catch (error) {
    console.error('Error during initialization:', error);
  }
};

const waitForModels = async (caseId) => {
  try {
    if (MODELS.length === 0) {
      await initializeModels(caseId);
    }

    if (!Array.isArray(MODELS) || MODELS.length === 0) {
      throw new Error("Models data is invalid or empty after initialization.");
    }

    console.log("Loaded models:", MODELS);
    return MODELS; // Return the valid models
  } catch (error) {
    console.error("Error in waitForModels:", error.message);
    throw new Error("Failed to load models. Please try again." + error.message);
  }
};

export { waitForModels };
export default MODELS;
