import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const FolderUpload = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const caseId = useSelector((state) => state.caseId);

  const handleFolderUpload = (event) => {
    const uploadedFiles = event.target.files;
    setFiles(Array.from(uploadedFiles)); // Convert FileList to array
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      alert("Please select a folder to upload.");
      return;
    }

    const formData = new FormData();

    // Loop through each file and append to FormData
    for (const file of files) {
      const relativePath = file.webkitRelativePath || file.name;
      formData.append("files", file, relativePath);
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `https://dubaismiledl.com/v2api/api/files/upload-folder/${caseId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response:", response.data);
      alert("Upload successful!");
    } catch (error) {
      console.error("Error uploading folder:", error);
      alert("Upload failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-6">
      <label className="block mb-2 font-medium">Upload Folder</label>
      <div className="mb-8">
        <input
          type="file"
          webkitdirectory="true"
          mozdirectory="true"
          directory="true"
          multiple
          onChange={handleFolderUpload}
        />
      </div>

      {files.length > 0 && (
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.webkitRelativePath}</li>
          ))}
        </ul>
      )}

      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
        onClick={handleUpload}
        disabled={loading}
      >
        {loading ? "Uploading..." : "Upload Folder"}
      </button>
    </div>
  );
};

export default FolderUpload;
