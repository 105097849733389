import UpperSvg from '../assets/svgs/upper.svg';
import TopSvg from '../assets/svgs/top.svg';
import LeftSvg from '../assets/svgs/left.svg';
import FrontSvg from '../assets/svgs/front.svg';
import RightSvg from '../assets/svgs/right.svg';
import BottomSvg from '../assets/svgs/bottom.svg';
import LowerSvg from '../assets/svgs/lower.svg';

const CONTROLS = [
  { label: "Upper", icon: UpperSvg, position: [0, 25, 0], fov: 25, isMobile: false },
  { label: "Top", icon: TopSvg, position: [0, -25, 0], fov: 25, isMobile: true },
  { label: "Right", icon: RightSvg, position: [-25, 0, 0], fov: 25, isMobile: true },
  { label: "Front", icon: FrontSvg, position: [0, 0, 25], fov: 25, isMobile: true },
  { label: "Left", icon: LeftSvg, position: [25, 0, 0], fov: 25, isMobile: true },
  { label: "Bottom", icon: BottomSvg, position: [0, 25, 0], fov: 25, isMobile: true },
  { label: "Lower", icon: LowerSvg, position: [0, 25, 0], fov: 25, isMobile: false },
];

export default CONTROLS;
