import React, { useCallback } from "react";

import classNames from "classnames";

import { useModel } from "../../context";
import FUNCTIONALITIES from "../../constants/functionalities";
import { useMediaQuery } from "react-responsive";
import DEVICE_SIZES from "../../constants/device-sizes";

const JawsControls = () => {
  const { model, setModel } = useModel();
  const isPhone = useMediaQuery({ maxWidth: DEVICE_SIZES.phone });

  const handleJaws = useCallback(
    (name) => {
      setModel((prev) => ({
        ...prev,
        jaws: { ...prev.jaws, [name]: !prev.jaws[name] },
      }));
    },
    [setModel]
  );

  return (
    <div className="rounded-xl border border-gray-100 bg-gray-50 p-1 max-w-lg mx-auto m-2 flex items-center justify-center flex-wrap w-full overflow-x-auto pb-3 md:py-1">
      <div className="flex items-center gap-2.5 text-sm font-medium">
        {FUNCTIONALITIES.filter(({ isMobile }) => isPhone ? isMobile === true : true).map(({ icon, label, value }) => (
          <div
            key={value}
            className={classNames(
              "relative flex items-center justify-center gap-3 rounded md:rounded-lg px-3 py-1 md:px-8 md:py-2 text-xs md:text-sm shadow cursor-pointer border-2 border-gray-50",
              model.jaws?.isSplit && label !== "Split"
                ? "!cursor-not-allowed"
                : model.jaws[value]
                ? "!border-black"
                : "text-gray-500 hover:text-gray-700"
            )}
            onClick={() =>
              model.jaws?.isSplit && label !== "Split" ? {} : handleJaws(value)
            }
          >
            <img src={icon} alt="icon" className="h-5 md:h-auto" />
            <p>{label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JawsControls;
