import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
import CONTROLS from "../constants/controls";
import Footer from "./footer";
import Header from "./header";
import CanvasDisplay from "./canvas/canvas-display";
import MODELS, { waitForModels } from "../constants/models";
import JAWS_IPR, { waitForJawIpr } from "../constants/jaws-ipr";
import { useModel } from "../context";
import { useParams } from "react-router-dom";

const Viewer = () => {
    const { caseId } = useParams();
    const { model, setModel } = useModel();
    const [isFooterShown, setIsFooterShown] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // Loading state for models
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const loadJawIprAndModels = async () => {
            try {
                await Promise.all([waitForModels(caseId), waitForJawIpr(caseId)]);
                setIsLoading(false); // Once loaded, update the loading state
            } catch (error) {
                console.error("Error loading models and Jaw IPR:", error);
                setError(error.message || "An unexpected error occurred while loading data.");
                setIsLoading(false); // Ensure the application stops loading
            }
        };

        loadJawIprAndModels();
    }, [caseId]);

    const handleModelChange = (name, value) => {
        setModel((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const updateStateFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.size > 0) {
            const newState = {
                isLoading: true,
                zoom: parseFloat(searchParams.get("zoom") || 0),
                orientation: JSON.parse(searchParams.get("orientation") || "{}"),
                jaws: JSON.parse(searchParams.get("jaws") || "{}"),
                additional: JSON.parse(searchParams.get("additional") || "[]"),
                displayedTeethIndex: parseInt(searchParams.get("displayedTeethIndex") || 0),
            };
            setModel(newState);
        }
    };

    useEffect(() => {
        handleModelChange("orientation", CONTROLS[3]);
        handleModelChange("isLoading", true);
        updateStateFromURL();
    }, []);

    // Block rendering and display error if an error occurred
    if (error) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="text-center">
                    <p className="text-red-500 font-semibold">{error}</p>
                    <button
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={() => window.location.reload()}
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    // Block the UI until models are loaded
    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <p>Loading 3D Models and Jaw IPR Data, please wait...</p>
            </div>
        );
    }

    return (
        <div className={classNames("min-h-screen relative flex flex-col", model.isDarkTheme ? 'bg-gray-500' : 'bg-gray-50')}>
            <Header />
            <CanvasDisplay setIsFooterShown={setIsFooterShown} />
            {isFooterShown ? <Footer /> : null}
            <ToastContainer />
        </div>
    );
};

export default Viewer;
