import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../Images/profile.png";
import down from "../../Images/downloading.png";
import logo from "../../Images/logo.png";
import axios from "axios";
import { Spin, Upload } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import JSZip from "jszip";
import FolderUpload from "../FolderUpload/folderUpload";

import {
  SendOutlined,
  LeftOutlined,
  UpOutlined,
  DownOutlined,
  CaretRightOutlined,
  UploadOutlined,
  CloseOutlined,
  FilePdfOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
const Inquiry = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [showExternalChat, setShowExternalChat] = useState(true);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const caseId = useSelector((state) => state.caseId);
  const token = useSelector((state) => state.token);
  const reset = useSelector((state) => state.reset);
  const [loading, setLoading] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [upFloading, setFUploading] = useState(false);
  const [dateP, setDateP] = useState(false);
  const [status, setStatus] = useState("");
  const [casee, setCasee] = useState("");
  const [num, setNum] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const [files, setFiles] = useState([]);
  const [filePdf, setFilePdf] = useState(null);
  const [creator, setCreator] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [fileName, setFileName] = useState("");
  const [pdfSize, setPdfSize] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [comment, setComment] = useState("");
  const toggleInternalChat = () => {
    setShowExternalChat(!showExternalChat);
    setShowInternalChat(!showInternalChat);
  };

  function downloadImage(url) {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "image.jpg";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://dubaismiledl.com/api/case/get-case-by-id/${caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setLoading(false);
        setInquiries(data?.data);
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://dubaismiledl.com/api/CaseFiles/get-casefiles?id=${caseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setLoading(false);
        const imageFiles = data?.data?.$values.filter(
          (file) =>
            file.fileType === ".jpg" ||
            file.fileType === ".jpeg" ||
            file.fileType === ".png"
        );
        const nonImageFiles = data?.data?.$values.filter(
          (file) =>
            (file.fileType === ".ply" ||
              file.fileType === ".obj" ||
              file.fileType === ".stl" ||
              file.fileType === ".dcm") &&
            file.createdFor === "New Case"
        );
        const imageUrls = imageFiles.map((file) => file.fileContent);
        const fileUrls = nonImageFiles.map((file) => file.fileContent);
        setFile(imageUrls);
        setFileUrl(fileUrls);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://dubaismiledl.com/api/case/get-case-by-id/${caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setLoading(false);
        setStatus(data?.data?.status);
        setInquiries(data?.data);
        const datee = data?.data?.deliveryPresentationDate.split("T")[0];
        setDateP(datee);
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [reset]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://dubaismiledl.com/api/Case/get-case-creater-profile?id=${caseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setLoading(false);
        setCreator(data?.data);
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [reset]);
  const sendHandler = async () => {
    const formData = new FormData();
    formData.append("CaseId", caseId);
    if (comment === "") {
      setErr("Please add comment");
      setShow(true);
    } else if (num === "") {
      setErr("Please add numbers of Aligners");
      setShow(true);
    } else if (casee === "") {
      setErr("Please add case Complexity");
      setShow(true);
      // } else if (files?.length === 0) {
      //   setErr("Please add presentation folder");
      //   setShow(true);
    } else if (filePdf === null) {
      setErr("Please add pdf presentation");
      setShow(true);
    } else {
      setLoading(true);
      formData.append("Comments", comment);
      formData.append("complexity", casee);
      formData.append("NumberOfAligner", num);
      formData.append("Files", filePdf);
      sendIprData(caseId, dataUpper, dataLower);
      // for (const file of files) {
      //   const fileContent = await file.content.async("uint8array");
      //   const blob = new Blob([fileContent.buffer]);
      //   formData.append("Files", blob, file.name);
      // }
      axios
        .post(`https://dubaismiledl.com/api/Case/comment-on-case`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          axios
            .post(
              `https://dubaismiledl.com/api/Case/case-status-reviewed?id=${caseId}`,
              null,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((data) => {
              setLoading(false);
              dispatch({ type: "RESET" });
            })
            .catch((err) => {
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    console.log("Image", file);
    console.log("other", fileUrl);
  }, [file, fileUrl]);
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDeletePdf = () => {
    setFilePdf(null);
  };
  const handleFileChange = (event, index) => {
    const selectedFile = event.target.files[0];
    files[index] = selectedFile;
    console.log(files);
  };
  const handlePdfFileChange = (event, index) => {
    const selectedFile = event.target.files[0];
    setFilePdf(selectedFile);
    console.log(selectedFile);
    setPdfName(selectedFile?.name);
    const fileSizeInBytes = selectedFile?.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    setPdfSize(fileSizeInMB.toFixed(2) + "MB");
  };
  const props = {
    customRequest: ({ file, onSuccess, onError }) => {
      // setUploading(true);
      console.log("file", file, onSuccess, onError);
      setFile(file.target.files[0]);
    },
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        console.log(file, fileList);
      }
    },
    accept: ".pdf",
    defaultFileList: [],
  };

  const handleZipFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    setFileName(file.name);
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    setFileSize(fileSizeInMB.toFixed(2) + "MB");

    const zip = new JSZip();
    const zipFile = await zip.loadAsync(file);

    const filesArray = [];
    zipFile.forEach((relativePath, zipEntry) => {
      if (!zipEntry.dir) {
        filesArray.push({
          name: zipEntry.name,
          content: zipEntry,
        });
      }
    });

    setFiles(filesArray);
    console.log("Fileeee", filesArray);
  };
  const handleDeleteFile = () => {
    setFiles([]);
    setFileName("");
    setFileSize("");
  };

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };
  const removeZeroValues = (data) => {
    return data.filter((item) => item.stripping !== 0 || item.aligner !== 0);
  };
  // Function to send data to the backend
  const sendIprData = (caseId, dataUpper, dataLower) => {
    axios
      .post(
        "https://dubaismiledl.com/v2api/api/ipr_data/save",
        {
          caseId: caseId,
          dataUpper: JSON.stringify(removeZeroValues(dataUpper)), // Convert dataUpper to JSON string
          dataLower: JSON.stringify(removeZeroValues(dataLower)), // Convert dataLower to JSON string
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Success:", response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [dataUpper, setDataUpper] = useState([
    {
      tooth_one: "Tooth_1",
      tooth_two: "Tooth_2",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_2",
      tooth_two: "Tooth_3",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_3",
      tooth_two: "Tooth_4",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_4",
      tooth_two: "Tooth_5",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_5",
      tooth_two: "Tooth_6",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_6",
      tooth_two: "Tooth_7",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_7",
      tooth_two: "Tooth_8",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_8",
      tooth_two: "Tooth_9",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_9",
      tooth_two: "Tooth_10",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_10",
      tooth_two: "Tooth_11",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_11",
      tooth_two: "Tooth_12",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_12",
      tooth_two: "Tooth_13",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_13",
      tooth_two: "Tooth_14",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_14",
      tooth_two: "Tooth_15",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_15",
      tooth_two: "Tooth_16",
      stripping: 0.0,
      aligner: 0,
    },
  ]);

  const [dataLower, setDataLower] = useState([
    {
      tooth_one: "Tooth_17",
      tooth_two: "Tooth_18",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_18",
      tooth_two: "Tooth_19",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_19",
      tooth_two: "Tooth_20",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_20",
      tooth_two: "Tooth_21",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_21",
      tooth_two: "Tooth_22",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_22",
      tooth_two: "Tooth_23",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_23",
      tooth_two: "Tooth_24",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_24",
      tooth_two: "Tooth_25",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_25",
      tooth_two: "Tooth_26",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_26",
      tooth_two: "Tooth_27",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_27",
      tooth_two: "Tooth_28",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_28",
      tooth_two: "Tooth_29",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_29",
      tooth_two: "Tooth_30",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_30",
      tooth_two: "Tooth_31",
      stripping: 0.0,
      aligner: 0,
    },
    {
      tooth_one: "Tooth_31",
      tooth_two: "Tooth_32",
      stripping: 0.0,
      aligner: 0,
    },
  ]);

  const handleInputChange = (setDataFn, index, field, value) => {
    setDataFn((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;
      return updatedData;
    });
  };

  return (
    <main>
      <div style={{ opacity: 1 }}>
        <div className="flex w-full p-2 sm:justify-center justify-start">
          <div className="w-[730px] flex flex-col gap-8 py-14 overflow-x-auto">
            <div
              onClick={() => router("/dashboard/inquires")}
              className="font-medium text-blue-600 flex flex-row items-center text-l cursor-pointer text-primary dark:primary self-start w-min"
            >
              <LeftOutlined className="w-3 h-3 mr-2 text-blue-600 text-primary dark:text-white" />
              BACK
            </div>
            <div className="flex flex-row justify-between items-center w-full py-6 px-6 rounded-xl border border-blue-200 shadow-sm bg-white undefined">
              <div>
                <h1 className="text-base font-medium">Impression Status</h1>
              </div>
              <span className="inline-flex items-center min-w-max px-3.5 py-0.5 rounded-md text-xs font-medium bg-indigo-100 text-indigo-500">
                {inquiries?.status}
              </span>
            </div>
            {status === "Pending Complete" && (
              <div class="p-6 border border-gray-200 shadow-sm rounded-md">
                <label class="font-bold text-lg">
                  Delivery Presentation Date
                </label>
                <p class="text-sm text-gray-500 mb-3">{dateP}</p>
              </div>
            )}
            {(status === "Reviewed" ||
              status === "Pending Complete" ||
              status === "Complete") && (
              <div className="">
                <div className="w-full p-6 border rounded-md mb-6">
                  <div className="flex justify-between mobile:flex-col">
                    <h1 className="text-lg mobile:text-sm mobile:mb-3 font-semibold leading-none">
                      Treatment Plan
                    </h1>
                    <Button
                      type="primary"
                      className="rounded-lg focus:outline-none focus:ring-4 text-white bg-primary hover:bg-primary-hover focus:ring-primary-faded dark:bg-primary dark:hover:bg-primary-hover dark:focus:ring-primary-hover px-3 py-1.5 mr-1 h-[41px] text-sm font-medium mb-3 mobile:text-xs mobile:h-[35px] mobile:w-[170px] flex items-center justify-center"
                    >
                      <a
                        href={`/viewer/${caseId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center"
                      >
                        <CaretRightOutlined className="text-white text-2xl pb-2" />
                        <span className="ml-2">See Full Presentation</span>
                      </a>
                    </Button>
                  </div>
                  <div className="my-1 h-0.5 w-full bg-gray-200"></div>
                  <div className="grid grid-cols-2 mobile:grid-cols-1">
                    <div className="flex flex-col mt-3">
                      <span className="font-medium text-sm text-gray-900">
                        No of Aligner Sets
                      </span>
                      <span className="text-base text-gray-500">
                        {inquiries?.numberOfAligner}
                      </span>
                    </div>
                    <div className="flex flex-col mt-3">
                      <span className="font-medium text-sm text-gray-900">
                        Dentist Name
                      </span>
                      <span className="text-base text-gray-500">
                        {creator?.firstName} {creator?.lastName}
                      </span>
                    </div>
                    <div className="flex flex-col mt-3">
                      <span className="font-medium text-sm text-gray-900">
                        Case Complexity
                      </span>
                      <span className="text-base text-gray-500">
                        {inquiries?.complexity}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="border rounded-md p-6">
              <div className="flex justify-between sm:flex-row flex-col">
                <h1 className="text-lg font-semibold leading-none">
                  Patient Details
                </h1>
              </div>
              <div className="my-5 h-0.5 w-full bg-gray-200"></div>
              <div className="mb-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-20 w-20 rounded-full" src={profile} />
                  </div>
                  <div className="ml-5">
                    <p className="sm:text-2xl text-xl font-semibold text-gray-700 tracking-wide">
                      {inquiries?.firstName} {inquiries?.lastName}
                    </p>
                    <div className="sm:text-sm text-gray-500 font-light text-xs"></div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Patient ID
                  </span>
                  <span className="text-base mt-1.5 font-normal leading-6 text-gray-500 max-w-[75%] text-end">
                    {inquiries?.$id}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Gender
                  </span>
                  <span className="text-base mt-1.5 font-normal capitalize leading-6 text-gray-500 max-w-[75%] text-end">
                    {inquiries?.gender}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Impression Type
                  </span>
                  <span className="text-base mt-1.5 font-normal leading-6 text-gray-500 max-w-[75%] text-end">
                    {inquiries?.impressionType}
                  </span>
                </div>
                {/* <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Arch
                  </span>
                  <span className="text-base mt-1.5 font-normal leading-6 text-gray-500 max-w-[75%] text-end">
                    DUAL
                  </span>
                </div> */}
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Age
                  </span>
                  <span className="text-base mt-1.5 font-normal capitalize leading-6 text-gray-500 max-w-[75%] text-end">
                    {inquiries?.age}
                  </span>
                </div>
              </div>
            </div>
            <div className="px-6 border rounded-md">
              <div>
                <div className="w-full py-6 rounded-md">
                  <h1 className="text-lg font-semibold leading-none">
                    Treatment Details
                  </h1>
                  <div className="my-4 h-0.5 w-full bg-gray-200"></div>
                  {/* <div className="flex flex-col mt-3">
                    <span className="font-medium text-base text-gray-900">
                      Do you want to IPR teeth?
                    </span>
                    <span className="text-base text-gray-500">
                      Teeth #: 0.1mm(27-26), 0.1mm(26-25), 0.1mm(25-24),
                      0.1mm(24-23), 0.1mm(23-22), 0.1mm(22-21), 0.1mm(8-9),
                      0.1mm(9-10), 0.1mm(7-8), 0.1mm(6-7), 0.1mm(5-6),
                      0.1mm(10-11), 0.1mm(11-12), 0.1mm(28-27)
                    </span>
                  </div> */}
                  <div className="flex flex-col mt-3">
                    <span className="font-medium text-base text-gray-900">
                      Note
                    </span>
                    <span className="text-base text-gray-500">
                      {inquiries?.notes}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-6 py-3 border rounded-md">
              <div>
                <div class="flex justify-between items-center">
                  <h1 class="text-lg font-bold text-gray-900">Images</h1>
                </div>
                <div class="my-3 h-[1px] w-full bg-gray-100"></div>
                <div className="flex flex-wrap gap-2 justify-center items-center  w-full">
                  {file.length > 0
                    ? file.map((imageUrl, index) => (
                        <div
                          key={index}
                          className="group border rounded-md relative w-[162px] h-[158px] overflow-hidden m-0"
                        >
                          <img
                            src={imageUrl}
                            alt="Placeholder"
                            className="w-full h-full object-cover rounded-md group-hover:brightness-50 transition-all duration-500"
                          />
                          <button
                            className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                            onClick={() => downloadImage(imageUrl)}
                          >
                            {/* <img
                          src={down}
                          className="w-[40.39px] h-[40.39px]"
                          alt="Download"
                        /> */}
                          </button>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
            <div class="px-6 py-3 border rounded-md">
              <div>
                <div class="flex justify-between items-center">
                  <h1 class="text-lg font-bold text-gray-900">Files</h1>
                </div>
                <div class="my-3 h-[1px] w-full bg-gray-100"></div>
                <div className="flex flex-col gap-5 w-full p-4">
                  {fileUrl.length > 0
                    ? fileUrl.map((url, index) => (
                        <div key={index}>
                          <a className="flex items-center justify-between gap-2 w-full">
                            <div
                              className="flex gap-4 cursor-pointer"
                              download
                              onClick={() => handleDownload(url)}
                            >
                              <FileZipOutlined className="mr-3 text-xl" />
                              <p className="hover:text-red-500">
                                {url.substring(url.lastIndexOf("/") + 1)}
                              </p>
                            </div>
                            <img
                              src={down}
                              alt=""
                              className="w-8 h-8 cursor-pointer"
                              download
                              onClick={() => handleDownload(url)}
                            />
                          </a>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>

            <div className="px-6 py-5 border rounded-md">
              <div className="flex justify-between sm:flex-row flex-col">
                <h1 className="text-lg font-semibold leading-none">
                  Clinic Details
                </h1>
              </div>
              <div className="my-5 h-0.5 w-full bg-gray-200"></div>
              <div className="mb-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      class="h-20 w-20 rounded-full"
                      src={creator?.imagePath ? creator.imagePath : profile}
                    />
                  </div>
                  <div className="ml-5">
                    <p className="sm:text-2xl text-xl font-semibold text-gray-700 tracking-wide">
                      {creator?.clinicName}
                    </p>
                    <div className="sm:text-sm text-gray-500 font-light text-xs">
                      {creator?.phoneNumber}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Dentist Name
                  </span>
                  <span className="text-base mt-1.5 font-normal leading-6 text-gray-500 max-w-[75%] text-end">
                    {creator?.firstName} {creator?.lastName}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold leading-none text-gray-900">
                    Dentist Email
                  </span>
                  <span className="text-base mt-1.5 font-normal leading-6 text-gray-500 max-w-[75%] text-end">
                    {creator?.email}
                  </span>
                </div>
              </div>
            </div>
            {status === "In Review" && (
              <div className="px-6 py-5 border rounded-md">
                <div className="p-2 flex items-center justify-between">
                  <div className="p-2 flex w-[50%] flex-col">
                    <label className="block mb-1 font-medium">
                      Case Complexity
                    </label>
                    <select
                      value={casee}
                      onChange={(e) => setCasee(e.target.value)}
                      className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                    >
                      <option value="" hidden selected>
                        Select an option
                      </option>
                      <option value="easy">Easy</option>
                      <option value="complex">Complex</option>
                      <option value="moderate">Moderate</option>
                    </select>
                  </div>
                  <div className="p-2 flex w-[50%] flex-col">
                    <label className="block mb-1 font-medium">
                      No of Aligners set
                    </label>
                    <input
                      type="number"
                      placeholder="No of Aligners set"
                      value={num}
                      onChange={(e) => setNum(e.target.value)}
                      className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>
                </div>
                <div className="p-2 flex flex-col">
                  <label className="block mb-1 font-medium">Your Message</label>
                  <textarea
                    type="text"
                    placeholder="Write text here."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            )}
            {status === "In Review" && (
              // Teeth Form Section
              <div className="p-6 border rounded-md mt-6">
                <div className="mb-8">
                  <h2 className="text-lg font-medium mb-4">Upper Jaw</h2>
                  <div className="grid grid-cols-4 gap-4 text-center font-medium py-2 border-b">
                    <div>Tooth One</div>
                    <div>Tooth Two</div>
                    <div>Stripping</div>
                    <div>Aligner</div>
                  </div>
                  {dataUpper.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between py-2 border-b"
                    >
                      <div className="w-[25%] text-center">
                        {item.tooth_one}
                      </div>
                      <div className="w-[25%] text-center">
                        {item.tooth_two}
                      </div>
                      <div className="w-[25%]">
                        <input
                          type="number"
                          value={item.stripping}
                          onChange={(e) =>
                            handleInputChange(
                              setDataUpper,
                              index,
                              "stripping",
                              e.target.value
                            )
                          }
                          className="w-full border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                      </div>
                      <div className="w-[25%]">
                        <input
                          type="number"
                          value={item.aligner}
                          onChange={(e) =>
                            handleInputChange(
                              setDataUpper,
                              index,
                              "aligner",
                              e.target.value
                            )
                          }
                          className="w-full border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-4">Lower Jaw</h2>
                  <div className="grid grid-cols-4 gap-4 text-center font-medium py-2 border-b">
                    <div>Tooth One</div>
                    <div>Tooth Two</div>
                    <div>Stripping</div>
                    <div>Aligner</div>
                  </div>
                  {dataLower.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between py-2 border-b"
                    >
                      <div className="w-[25%] text-center">
                        {item.tooth_one}
                      </div>
                      <div className="w-[25%] text-center">
                        {item.tooth_two}
                      </div>
                      <div className="w-[25%]">
                        <input
                          type="number"
                          value={item.stripping}
                          onChange={(e) =>
                            handleInputChange(
                              setDataLower,
                              index,
                              "stripping",
                              e.target.value
                            )
                          }
                          className="w-full border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                      </div>
                      <div className="w-[25%]">
                        <input
                          type="number"
                          value={item.aligner}
                          onChange={(e) =>
                            handleInputChange(
                              setDataLower,
                              index,
                              "aligner",
                              e.target.value
                            )
                          }
                          className="w-full border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {status === "In Review" && (
              <div className="px-6 py-5 border w-full flex flex-col Items-center justify-center rounded-md">
                <label className="block mb-2 font-medium">
                  Upload Pdf Presentation
                </label>
                <div
                  className={`flex items-center justify-center rounded-lg text-center w-full ${
                    filePdf
                      ? ""
                      : "h-[13rem] border-dashed border-2 border-primary"
                  } mb-8 relative`}
                >
                  {filePdf ? (
                    <div className="w-full flex items-center justify-between">
                      <div className="flex">
                        <FilePdfOutlined className="mr-3 text-4xl" />
                        <div>
                          <h1>{pdfName}</h1>
                          <p className="text-sm text-left w-full">{pdfSize}</p>
                        </div>
                      </div>
                      <CloseOutlined
                        onClick={handleDeletePdf}
                        className="text-2xl  mt-2 text-red-500 cursor-pointer "
                      />
                    </div>
                  ) : (
                    <div className="w-full flex flex-col gap-1 flex items-center justify-center">
                      <UploadOutlined className="text-5xl text-primary cursor-pointer" />
                      <label
                        htmlFor="pdf-upload"
                        className="mt-4 w-full hover:text-blue-400 cursor-pointer inline-block bg-primary text-black px-4 rounded-lg"
                      >
                        Click Here to Upload
                      </label>
                      <p className="text-sm">Upload pdf presentation here</p>
                      <input
                        id="pdf-upload"
                        type="file"
                        hidden
                        accept=".pdf"
                        onChange={handlePdfFileChange}
                      />
                    </div>
                  )}
                </div>
                <FolderUpload></FolderUpload>
                {/* <div className="mt-6">
                  <label className="block  mb-2 font-medium">
                    Upload Presentation
                  </label>
                  <div
                    className={`flex items-center justify-center rounded-lg text-center w-full ${
                      files.length > 0
                        ? ""
                        : "h-[13rem] border-dashed border-2 border-primary"
                    } mb-8 relative`}
                  >
                    {files.length > 0 ? (
                      <div className="w-full flex items-center justify-between">
                        <div className="flex">
                          <FileZipOutlined className="mr-3 text-4xl" />
                          <div>
                            <h1>{fileName}</h1>
                            <p className="text-sm text-left w-full">
                              {fileSize}
                            </p>
                          </div>
                        </div>
                        <CloseOutlined
                          onClick={handleDeleteFile}
                          className="text-2xl  mt-2 text-red-500 cursor-pointer "
                        />
                      </div>
                    ) : (
                      <div className="w-full flex flex-col gap-1 flex items-center justify-center">
                        <UploadOutlined
                          htmlFor="zip-upload"
                          className="text-5xl text-primary cursor-pointer"
                        />
                        <label
                          htmlFor="zip-upload"
                          className="mt-4 w-full  hover:text-blue-400 cursor-pointer inline-block bg-primary text-black px-4 rounded-lg"
                        >
                          Click Here to Upload ZIP
                        </label>
                        <p className="text-sm">Upload the Model folder</p>
                        <input
                          id="zip-upload"
                          type="file"
                          hidden
                          accept=".zip"
                          onChange={handleZipFileChange}
                        />
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="flex items-center justify-end">
                  <Button
                    onClick={sendHandler}
                    type="primary"
                    class="rounded-lg focus:outline-none focus:ring-4 text-white bg-blue-400 hover:bg-blue-500 focus:ring-blue-faded px-3 py-1.5 mr-1 h-[41px] text-sm font-medium mobile:text-xs mobile:h-[35px] mobile:w-[170px] flex items-center justify-center"
                  >
                    Send to Doctor
                  </Button>
                </div>
              </div>
            )}
            {(status === "Reviewed" ||
              status === "Pending Complete" ||
              status === "Complete") && (
              <div class="p-6 border border-gray-200 shadow-sm rounded-md">
                <label class="font-bold text-lg">Message from Admin</label>
                <p class="text-sm text-gray-500 mb-3">{inquiries?.comments}</p>
              </div>
            )}
            <div className="flex flex-row justify-center">
              <div className="rounded-xl border-2 w-full">
                {showExternalChat && (
                  <div className="font-normal bg-blue-100 p-4 py-6 rounded-t-lg underline-offset-4 flex flex-row justify-between items-center cursor-pointer">
                    <div>
                      Conversation with{" "}
                      <span className="font-semibold underline">
                        {creator?.firstName} {creator?.lastName}
                      </span>{" "}
                      &nbsp;and&nbsp;
                      <span className="font-semibold underline">1 other</span>
                    </div>
                    <UpOutlined
                      onClick={toggleInternalChat}
                      className="w-4 h-4 text-gray-800 dark:text-white"
                    />
                  </div>
                )}
                {!showExternalChat && (
                  <div className="font-normal bg-blue-100 p-4 py-6 rounded-t-lg underline-offset-4 flex flex-row justify-between items-center cursor-pointer">
                    <div>
                      <span className="font-semibold">
                        General Conversation
                      </span>
                    </div>
                    <DownOutlined
                      onClick={toggleInternalChat}
                      className="w-4 h-4 text-gray-800 dark:text-white"
                    />
                  </div>
                )}
                {showExternalChat && (
                  <div>
                    <div className="flex flex-col items-center justify-center h-[300px]">
                      <span className="mt-4 text-gray-500 text-sm">
                        No messages yet, start a conversation 😁
                      </span>
                    </div>
                    <div className="p-4 mt-4 bg-gray-50 flex flex-row gap-4 rounded-b-lg">
                      <input
                        className="p-3 w-full rounded-lg shadow-sm outline-none text-sm font-normal border border-gray-300 disabled:bg-gray-50 disabled:cursor-not-allowed"
                        placeholder="Write text here ..."
                        type="text"
                        value=""
                      />
                      <input className="hidden" type="file" />
                      <SendOutlined className="text-3xl cursor-pointer" />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex mt-4 flex-row justify-center">
              <div className="rounded-xl border-2 w-full">
                {showInternalChat && (
                  <div className="font-normal bg-blue-100 p-4 py-6 rounded-t-lg underline-offset-4 flex flex-row justify-between items-center cursor-pointer">
                    <div>
                      Conversation with{" "}
                      <span className="font-semibold underline">
                        {creator?.firstName} {creator?.lastName}
                      </span>{" "}
                      &nbsp;and&nbsp;
                      <span className="font-semibold underline">1 other</span>
                    </div>
                    <UpOutlined
                      onClick={toggleInternalChat}
                      className="w-4 h-4 text-gray-800 dark:text-white"
                    />
                  </div>
                )}
                {!showInternalChat && (
                  <div className="font-normal bg-blue-100 p-4 py-6 rounded-t-lg underline-offset-4 flex flex-row justify-between items-center cursor-pointer">
                    <div>
                      <span className="font-semibold">
                        Internal Conversation
                      </span>
                    </div>
                    <DownOutlined
                      onClick={toggleInternalChat}
                      className="w-4 h-4 text-gray-800 dark:text-white"
                    />
                  </div>
                )}
                {showInternalChat && (
                  <div>
                    <div className="flex flex-col items-center justify-center h-[300px]">
                      <span className="mt-4 text-gray-500 text-sm">
                        No messages yet, start a conversation 😁
                      </span>
                    </div>
                    <div className="p-4 mt-4 bg-gray-50 flex flex-row gap-4 rounded-b-lg">
                      <input
                        className="p-3 w-full rounded-lg shadow-sm outline-none text-sm font-normal border border-gray-300 disabled:bg-gray-50 disabled:cursor-not-allowed"
                        placeholder="Write text here ..."
                        type="text"
                        value=""
                      />
                      <input className="hidden" type="file" />
                      <SendOutlined className="text-3xl cursor-pointer" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          loading ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[500] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 flex-col">
          <p className="text-center text-xl font-semibold text-blue-700">
            <Spin size="medium" /> &nbsp;&nbsp; Loading ...
          </p>
        </div>
      </div>
      <div
        className={`${
          uploading ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[500] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-full pb-6 flex items-center p-3 flex-col">
          <p className="text-center text-xl font-semibold text-blue-700">
            <Spin size="medium" /> &nbsp;&nbsp; Uploading presentation files...
          </p>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[500] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[8px] right-[40%]"
            onClick={() => setShow(false)}
          >
            <img src={logo} alt="" className="w-[5rem] h-[4rem]" />
          </div>
          <p className="text-center mt-[5rem] mb-[2rem]">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </main>
  );
};

export default Inquiry;
