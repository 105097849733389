import { createContext, useContext, useState } from "react";

const jaws = {
  isUpperJaw: true,
  isLowerJaw: true,
  isAttachment: false,
  isIpr: false,
  isSplit: false,
  isElastic: false,
};

const initialState = {
  isLoading: false,
  zoom: 0,
  orientation: {},
  jaws,
  additional: [],
  displayedTeethIndex: 0,
};

const Context = createContext(initialState);

export const useModel = () => useContext(Context);

const ContextProvider = ({ children }) => {
  const [model, setModel] = useState(initialState);

  return (
    <Context.Provider value={{ model, setModel }}>{children}</Context.Provider>
  );
};

export default ContextProvider;
