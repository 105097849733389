import React, { useEffect } from "react";

import classNames from "classnames";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";

import JawModel from "./jaw-model";
import MODELS, { waitForModels } from "../constants/models";
import CanvasCamera from "./canvas/canvas-camera";
import JAWS_IPR from "../constants/jaws-ipr";
import { useModel } from "../context";

const SplitScreen = (props) => {
  const { position, fov, jaws, setIsFooterShown } = props;
  const { model } = useModel();

  useEffect(() => {
    setIsFooterShown(!jaws.isSplit);
  }, [jaws.isSplit]);

  return (
    <div className={classNames("h-full", jaws.isSplit ? "w-full flex" : model.isLoading ? "" : "hidden")}>
      <div className={classNames("w-1/2", jaws.isSplit ? 'border-r-2' : '')}>
        <Canvas
          frameloop="demand"
          shadows
          gl={{ preserveDrawingBuffer: true }}
          className="w-full"
        >
          <CanvasCamera position={position} fov={fov} />
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            rotateSpeed="0.5"
          />

          <group visible={jaws.isSplit}>
            <JawModel
              upperJawModel={MODELS.at(0).upperJaw}
              lowerJawModel={MODELS.at(0).lowerJaw}
              isUpperJaw={jaws.isUpperJaw}
              isLowerJaw={jaws.isLowerJaw}
              isSplit
              isAttachment={false}
              isElastic={false}
            />
          </group>
          <Preload all />
        </Canvas>
      </div>
      <div className="w-1/2">
        <Canvas
          frameloop="demand"
          shadows
          gl={{ preserveDrawingBuffer: true }}
          className="w-full"
        >
          <CanvasCamera position={position} fov={fov} />
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            rotateSpeed="0.5"
          />
          <group visible={jaws.isSplit}>
            <JawModel
              upperJawModel={MODELS.at(-1).upperJaw}
              lowerJawModel={MODELS.at(-1).lowerJaw}
              isUpperJaw={jaws.isUpperJaw}
              isLowerJaw={jaws.isLowerJaw}
              iprModel={JAWS_IPR}
              isAttachment
              isIpr
              isSplit
            />
          </group>
          <Preload all />
        </Canvas>
      </div>
    </div>
  );
};

export default SplitScreen;
