import axios from "axios";

// Initialize the JAWS_IPR object with empty values
let JAWS_IPR = {
  upperJawIpr: [],
  lowerJawIpr: [],
};

// Function to fetch Jaw IPR data from the API
const getJawIprFromAPI = async (caseId) => {
  try {
    const response = await axios.get(`https://dubaismiledl.com/v2api/api/ipr_data/get/${caseId}`);
    if (response.status === 200 && response.data) {
      const { dataUpper, dataLower } = response.data;
      const parsedDataUpper = JSON.parse(dataUpper);
      const parsedDataLower = JSON.parse(dataLower);

      // Update JAWS_IPR object in place to maintain reference integrity
      JAWS_IPR.upperJawIpr = parsedDataUpper.map((item) => ({
        tooth_one: item.tooth_one || '',
        tooth_two: item.tooth_two || '',
        stripping: item.stripping || 0.0,
        aligner: item.aligner || 0,
      }));
      JAWS_IPR.lowerJawIpr = parsedDataLower.map((item) => ({
        tooth_one: item.tooth_one || '',
        tooth_two: item.tooth_two || '',
        stripping: item.stripping || 0.0,
        aligner: item.aligner || 0,
      }));
    } else {
      console.error("Failed to fetch Jaw IPR data or unexpected response format.");
    }
  } catch (error) {
    console.error("Error fetching Jaw IPR data:", error);
  }
};

// Function to initialize JAWS_IPR data
const initializeJawIpr = async (caseId) => {
  try {
    await getJawIprFromAPI(caseId);
  } catch (error) {
    console.error("Error initializing Jaw IPR:", error);
  }
};

const sanitizeData = (JAWS_IPR) => {
  return {
    upperJawIpr: JAWS_IPR.upperJawIpr.filter(item => item.stripping !== 0.0 && item.aligner !== 0),
    lowerJawIpr: JAWS_IPR.lowerJawIpr.filter(item => item.stripping !== 0.0 && item.aligner !== 0),
  };
};

// Function to wait for JAWS_IPR data
const waitForJawIpr = async (caseId) => {
  try {
    if (JAWS_IPR.upperJawIpr.length === 0 || JAWS_IPR.lowerJawIpr.length === 0) {
      await initializeJawIpr(caseId);
    }

    console.log(sanitizeData(JAWS_IPR));
    return sanitizeData(JAWS_IPR);
  }
  catch (error) {
    console.error("Error in Jaw-ipr:", error.message);
    throw new Error("Failed to Jaw-ipr. Please try again." + error.message);
  }
};

// Export functions and data
export { waitForJawIpr, initializeJawIpr };
export default JAWS_IPR;