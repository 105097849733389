import React from "react";

import classNames from "classnames";

import CONTROLS from "../../constants/controls";
import { useModel } from "../../context";
import { useMediaQuery } from "react-responsive";
import DEVICE_SIZES from "../../constants/device-sizes";

const OrientationControls = () => {
  const { model, setModel } = useModel();
  const isPhone = useMediaQuery({ maxWidth: DEVICE_SIZES.phone });

  const handleJawSelection = (isUpper, isLower, additionalControls = []) => {
    setModel((prev) => ({
      ...prev,
      jaws: { ...prev.jaws, isUpperJaw: isUpper, isLowerJaw: isLower },
      additional: additionalControls,
    }));
  };

  const handleOrientationChange = (value) => {
    setModel((prev) => ({
      ...prev,
      orientation: value,
    }));
  };

  const handleSelect = (control) => {
    const { label } = control;
    if (["Top", "Right", "Front", "Left", "Bottom"].includes(label)) {
      handleOrientationChange(control);

      if (label === "Top") handleJawSelection(true, false, ["Upper"]);
      else if (label === "Bottom") handleJawSelection(false, true, ["Lower"]);
      else handleJawSelection(true, true);
    } else if (["Upper", "Lower"].includes(label)) {
      const isUpper = label === "Upper";

      if (!model?.additional?.includes(label)) {
        handleJawSelection(isUpper, !isUpper, [label]);
      } else {
        handleJawSelection(true, true);
      }
    }
  };

  return (
    <div className="rounded-xl border border-gray-100 bg-gray-50 p-1 max-w-[56rem] mx-auto m-2 lg:mt-5 flex items-center justify-center overflow-x-auto pb-3 md:py-1">
      <div className="flex items-center flex-wrap lg:flex-nowrap justify-center gap-2.5 text-sm font-medium">
        {CONTROLS.filter((({ isMobile }) => isPhone ? isMobile === true : true)).map((control) => {
          const isSelected =
            model?.orientation?.label === control.label ||
            model?.additional?.includes(control.label);

          return (
            <div
              key={control.label}
              className={classNames(
                "relative flex items-center justify-center gap-3 rounded md:rounded-lg px-3 py-1 md:px-8 md:py-2 text-xs md:text-sm shadow cursor-pointer",
                isSelected
                  ? "bg-gray-800 text-white"
                  : "text-gray-500 hover:text-gray-700"
              )}
              onClick={() => handleSelect(control)}
            >
              <img src={control.icon} alt="teeth" />
              <p>{control.label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrientationControls;
