const JawsAligner = () => {
  return (
    <div className="flex items-center justify-center mb-3">
      <div className="flex flex-col items-center border-r border-gray-700 py-3 px-6">
        <p className="font-bold">12</p>
        <p className="font-sm text-gray-500">Upper</p>
      </div>
      <div className="flex flex-col items-center py-3 px-6">
        <p className="font-bold">12</p>
        <p className="font-sm text-gray-500">Lower</p>
      </div>
    </div>
  );
};

export default JawsAligner;
