import { useCallback, useEffect, useRef, useState } from "react";

import { IoPlayBack, IoPlayBackOutline, IoPauseCircle, IoPlayCircleOutline, IoPlayForward, IoPlayForwardOutline, IoPlaySkipBack, IoPlaySkipBackOutline, IoPlaySkipForward, IoPlaySkipForwardOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useProgress } from "@react-three/drei";

import DEVICE_SIZES from "../../constants/device-sizes";
import { useModel } from "../../context";
import MODELS from "../../constants/models";

const PlayerControls = () => {
  const isPhone = useMediaQuery({ maxWidth: DEVICE_SIZES.phone });
  const [indexes] = useState([...Array(MODELS.length).keys()]);
  const intervalRef = useRef(null);
  const { model, setModel } = useModel();
  const { active } = useProgress();

  const [controls, setControls] = useState({
    play: false,
    forward: false,
    back: false,
    skipForward: false,
    skipBack: false,
  });

  const resetControls = useCallback((activeKey) => {
    setControls(_ => ({
      play: false,
      forward: false,
      back: false,
      skipForward: false,
      skipBack: false,
      [activeKey]: true,
    }));
  }, []);

  const handlePlay = useCallback(() => {
    if (controls.play) {
      clearInterval(intervalRef.current);
      resetControls("");
    } else {
      resetControls("play");

      if (model.displayedTeethIndex === indexes.length - 1) {
        setModel(prev => ({ ...prev, displayedTeethIndex: 0 }));
      }

      intervalRef.current = setInterval(() => {
        setModel(prev => {
          if (prev.displayedTeethIndex < indexes.length - 1 && !active) {
            return { ...prev, displayedTeethIndex: prev.displayedTeethIndex + 1 };
          } else {
            clearInterval(intervalRef.current);
            resetControls("");
            return prev;
          }
        });
      }, 300);
    }
  }, [controls.play, indexes, model.displayedTeethIndex, setModel, active, resetControls]);

  const handleSkip = useCallback((direction) => {
    resetControls(direction);
    setModel(prev => ({
      ...prev,
      displayedTeethIndex: direction === "skipBack" ? 0 : indexes.length - 1
    }));
    setTimeout(() => resetControls(""), 150);
  }, [indexes, setModel, resetControls]);

  const handleStep = useCallback((step) => {
    resetControls(step > 0 ? "forward" : "back");
    setModel(prev => ({
      ...prev,
      displayedTeethIndex: Math.min(Math.max(prev.displayedTeethIndex + step, 0), indexes.length - 1)
    }));
    setTimeout(() => resetControls(""), 150);
  }, [indexes, setModel, resetControls]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
      <div className="flex items-center justify-center w-56 gap-3 mx-auto cursor-pointer px-4 py-2 bg-gray-50 rounded-md border border-black border-dashed">
        <div onClick={() => handleSkip("skipBack")}>
          {controls.skipBack ? (
            <IoPlaySkipBack size={isPhone ? 12 : 20} />
          ) : (
            <IoPlaySkipBackOutline size={isPhone ? 12 : 20} />
          )}
        </div>
        <div onClick={() => handleStep(-1)}>
          {controls.back ? (
            <IoPlayBack size={isPhone ? 20 : 35} />
          ) : (
            <IoPlayBackOutline size={isPhone ? 20 : 35} />
          )}
        </div>
        <div onClick={handlePlay}>
          {controls.play ? (
            <IoPauseCircle size={isPhone ? 35 : 50} />
          ) : (
            <IoPlayCircleOutline size={isPhone ? 35 : 50} />
          )}
        </div>
        <div onClick={() => handleStep(1)}>
          {controls.forward ? (
            <IoPlayForward size={isPhone ? 20 : 35} />
          ) : (
            <IoPlayForwardOutline size={isPhone ? 20 : 35} />
          )}
        </div>
        <div onClick={() => handleSkip("skipForward")}>
          {controls.skipForward ? (
            <IoPlaySkipForward size={isPhone ? 12 : 20} />
          ) : (
            <IoPlaySkipForwardOutline size={isPhone ? 12 : 20} />
          )}
        </div>
      </div>
  );
};

export default PlayerControls;