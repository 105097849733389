import { memo, useState } from "react";

import classNames from "classnames";
import Lottie from "lottie-react";
import { useMediaQuery } from "react-responsive";

import MODELS from "../constants/models";
import DEVICE_SIZES from "../constants/device-sizes";
import AnimationData from "../assets/lottie/player.json";
import { useModel } from "../context";

const PlayerProgressIndicator = memo(() => {
  const { model } = useModel();
  const [indexes] = useState([...Array(MODELS.length).keys()]);
  const isPhone = useMediaQuery({ maxWidth: DEVICE_SIZES.phone });
  const progress = ((model.displayedTeethIndex + 1) / indexes.length) * 100;

  const radiusLower = 60;
  const radiusMiddle = 67.5;
  const radiusOuter = 75;
  const spacing = 10;
  const smallCircleRadius = radiusOuter + spacing + 10;
  const smallCircleSize = 20;
  const circumferenceMiddle = 2 * Math.PI * radiusMiddle;
  const strokeDashoffset = circumferenceMiddle - (progress / 100) * circumferenceMiddle;
  const angleStep = (2 * Math.PI) / indexes.length;
  const progressAngle = (progress / 100) * 2 * Math.PI;
  const xSmallCircle = 80 + smallCircleRadius * Math.cos(progressAngle);
  const ySmallCircle = 80 + smallCircleRadius * Math.sin(progressAngle);
  const textRotationAngle = (progressAngle * 180) / Math.PI + 90;

  return (
    <div className={classNames("relative items-center justify-center w-96 h-96 flex-grow", isPhone ? "hidden" : "flex")}>
      <svg className="absolute w-full h-full z-10 transform -rotate-90" viewBox="-40 -40 240 240">
        <circle cx="80" cy="80" r={radiusLower} stroke="red" strokeWidth="1" fill="none" />
        <circle cx="80" cy="80" r={radiusMiddle} stroke="lightgray" strokeWidth="7" fill="none" strokeDasharray={circumferenceMiddle} strokeDashoffset={strokeDashoffset} strokeLinecap="round" className="transition-all duration-300" />
        <circle cx="80" cy="80" r={radiusOuter} stroke="red" strokeWidth="1" fill="none" />
        {indexes.map((_, i) => {
          const angle = angleStep * i;
          const x1 = 80 + (radiusLower + spacing) * Math.cos(angle); 
          const y1 = 80 + (radiusLower + spacing) * Math.sin(angle);
          const x2 = 80 + (radiusOuter - spacing) * Math.cos(angle);
          const y2 = 80 + (radiusOuter - spacing) * Math.sin(angle);
          return (
            <line key={i} x1={x1} y1={y1} x2={x2} y2={y2} stroke="black" strokeWidth="1" />
          );
        })}
        <circle cx={xSmallCircle} cy={ySmallCircle} r={smallCircleSize / 2} fill="white" stroke="black" strokeWidth="2" />
        <text x={xSmallCircle} y={ySmallCircle} textAnchor="middle" alignmentBaseline="middle" fontSize="10" fill="black" fontWeight="bold" transform={`rotate(${textRotationAngle}, ${xSmallCircle}, ${ySmallCircle})`}>
          {model.displayedTeethIndex + 1}
        </text>
      </svg>
      <div className="flex items-center justify-center w-32 h-32 bg-white rounded-full text-black text-xl font-bold">
        <Lottie animationData={AnimationData} />
      </div>
    </div>
  );
});

export default PlayerProgressIndicator;
