import { useEffect } from "react";

import { useThree } from "@react-three/fiber";

const CanvasCamera = ({ position, fov }) => {
  const { camera, invalidate } = useThree();

  useEffect(() => {
    camera.position.set(...position);
    camera.fov = fov;
    camera.updateProjectionMatrix();
    invalidate();
  }, [camera, position, fov, invalidate]);

  return null;
};

export default CanvasCamera;
