import { useRef } from "react";

import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";

const Billboard = ({ children, position }) => {
  const ref = useRef();
  const { camera } = useThree();

  useFrame(() => {
    if (ref.current) {
      const direction = new Vector3().subVectors(camera.position, ref.current.position);
      ref.current.lookAt(direction.add(ref.current.position));
    }
  });

  return (
    <group ref={ref} position={position}>
      {children}
    </group>
  );
};

export default Billboard;
