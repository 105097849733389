import React from "react";

import { FiZoomIn, FiZoomOut } from "react-icons/fi";

import { useModel } from "../../context";

const ZoomButtons = () => {
  const { model, setModel } = useModel();

  const handleZoomChange = (name, value) => setModel((prevModel) => ({ ...prevModel, [name]: value }));

  return (
      <div className="m-5 absolute bottom-0 lg:bottom-auto flex gap-2 z-10">
        <div
          className="bg-gray-50 border shadow cursor-pointer rounded-md active:ring ring-offset-2 ring-gray-200 p-1"
          onClick={() => handleZoomChange("zoom", model.zoom - 3)}
        >
          <FiZoomIn size={30} />
        </div>
        <div
          className="bg-gray-50 border shadow cursor-pointer rounded-md active:ring ring-offset-2 ring-gray-200 p-1"
          onClick={() => handleZoomChange("zoom", model.zoom + 3)}
        >
          <FiZoomOut size={30} />
        </div>
    </div>
  );
};

export default ZoomButtons;
