import React from "react";

import { toast } from "react-toastify";

import { useModel } from "../../context";
import { TOASTIFY_OPTIONS } from "../../constants/toastify";
import { FaRegShareFromSquare } from "react-icons/fa6";

const ShareButton = () => {
  const { model } = useModel();

  const handleShareClick = async () => {
    const queryParams = new URLSearchParams({
      zoom: model.zoom,
      orientation: JSON.stringify(model.orientation),
      jaws: JSON.stringify(model.jaws),
      additional: JSON.stringify(model.additional),
      displayedTeethIndex: model.displayedTeethIndex,
    });

    const shareableUrl = `${window.location.origin}?${queryParams.toString()}`;

    try {
      await navigator.clipboard.writeText(shareableUrl);
      toast.success("Link copied to clipboard!", TOASTIFY_OPTIONS);
    } catch (error) {
      toast.error("Failed to copy the link to clipboard!", TOASTIFY_OPTIONS);
    }
  };

  return (
    <div className="absolute right-0 bg-gray-50 border shadow cursor-pointer rounded-md active:ring ring-offset-2 ring-gray-200 p-2 m-5 hidden lg:flex items-center gap-2" onClick={handleShareClick}>
      <FaRegShareFromSquare />
      <p>Share</p>
    </div>
  );
};

export default ShareButton;
