import { useCallback, useEffect } from "react";

import { Html } from "@react-three/drei";
import { PuffLoader } from "react-spinners";

import { useModel } from "../../context";

const CanvasLoader = () => {
  const { setModel } = useModel();

  const handleIsLoadingChange = useCallback((value) => {
    setModel((prevModel) => ({
      ...prevModel,
      isLoading: value,
    }));
  }, [setModel]);

  useEffect(() => {
    return () => handleIsLoadingChange(false);
  }, [handleIsLoadingChange]);

  return (
    <Html as="div" center className="flex flex-col items-center justify-center">
      <PuffLoader color="lightgrey" />
    </Html>
  );
};

export default CanvasLoader;
