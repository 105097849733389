import { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";
import { useMediaQuery } from "react-responsive";

import JawModel from "../jaw-model";
import CanvasCamera from "./canvas-camera";
import MODELS, { waitForModels } from "../../constants/models";
import JAWS_IPR from "../../constants/jaws-ipr";
import SplitScreen from "../split-screen";
import classNames from "classnames";
import { useModel } from "../../context";
import DEVICE_SIZES from "../../constants/device-sizes";

const CanvasDisplay = (props) => {
  const { setIsFooterShown } = props;
  const { model, setModel } = useModel();
  const { position, fov } = model.orientation;
  const isPhone = useMediaQuery({ maxWidth: DEVICE_SIZES.phone });

  useEffect(() => {
    setModel((prev) => ({
      ...prev,
      zoom: fov,
    }));
  }, [fov]);

  useEffect(() => {
    setModel((prev) => ({
      ...prev,
      zoom: Math.min(Math.max(prev.zoom, 15), 75),
    }));
  }, [model.zoom]);

  return (
    <div className="flex-grow flex items-center justify-center h-[50vh] w-full overflow-hidden">
      <SplitScreen
        position={position}
        jaws={model.jaws}
        fov={model.zoom}
        setIsFooterShown={setIsFooterShown}
      />
      <Canvas
        frameloop="demand"
        shadows
        gl={{ preserveDrawingBuffer: true }}
        className={classNames("h-full", model.jaws?.isSplit ? "hidden" : "w-full flex")}
      >
        <CanvasCamera position={position} fov={model.zoom} />
        <OrbitControls
          enableZoom={isPhone ? true : false}
          enablePan={false}
          rotateSpeed="0.5"
        />
        {MODELS.map((dataModel, index) => (
          <group key={index} visible={index === model.displayedTeethIndex && !model.jaws?.isSplit}>
            <JawModel
              upperJawModel={dataModel.upperJaw}
              lowerJawModel={dataModel.lowerJaw}
              iprModel={JAWS_IPR}
              {...model.jaws}
            />
          </group>
        ))}
        <Preload all />
      </Canvas>
    </div>
  );
};

export default CanvasDisplay;
